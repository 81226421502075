<template>
	<div>
    <Loader v-if="!pipeline || pipeline.length == 0" />
    

    <div class="dashboard__container pt-3">
      <Loader v-if="performingRequest" />
      <div class="dashboard__container--body mb-5"  v-if="pipeline && pipeline.length >= 1" >
        <h4 style="color:white;">Active Pipeline</h4>
        <TrackerTable classification="pipeline" :showAccurate="showAccurate" :showDrug="showDrug" :showBack="showBack" :pipeline="pipeline" />
      </div>

      <div class="dashboard__container--body pt-5" v-if="pipelineHired && pipelineHired.length >= 1" >
        <h4 style="color:white;">Hired Workers</h4>

        <TrackerTable classification="hired" :showAccurate="showAccurate" :showDrug="showDrug" :showBack="showBack" :pipeline="pipelineHired" />

      </div>

      <div class="dashboard__container--body pt-5" v-if="pipelineCancelled && pipelineCancelled.length >= 1" >
        <h4 style="color:white;">Cancelled Workers</h4>

        <TrackerTable classification="cancelled" :showAccurate="showAccurate" :showDrug="showDrug" :showBack="showBack" :pipeline="pipelineCancelled" />

      </div>
    </div>
  </div>
</template>

<style scoped>
.dashboard {
  background: rgb(20 25 32 / 93%) !important;
  padding-top: 2rem;
  padding-bottom: 4rem;
  min-height: calc(100vh - 6rem);
}
.fa-2x {
  font-size: 2rem;
}
h4 {
  opacity: .62;
}
h5 {
  font-size: 1.6rem;
  opacity: 1;
}
input {
  font-size: 1.4rem;
  background: #eee;
  border: none;
}
/* .btn__flat {
  color: white;
} */
.dashboard__container--header {
  align-items: flex-start;
}

.cancelled h5 {
    color: red !important;
  }
  .dashboard__container {
    max-width: 90%;
  }
</style>




<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
// import UserTable from '@/components/UserTable.vue'
import PipelineNote from '@/components/PipelineNote.vue'
import TrackerTable from '@/components/TrackerTable.vue'
import * as moment from 'moment'
import router from '@/router'

export default {
  name: 'Tracker',
  data() {
    return {
      performingRequest: false,
      activeItem: '',
      showShiftPreference: false,
      columns: [
      {
        field: 'photoUrl',
        sortable: false,
        width:'60px',
        tdClass: 'text-center',
      },
      {
        label: 'Candidates',
        field: 'fullName',
        sortable: false,
        width:'200px',
      },
      {
        label: 'Notes',
        field: 'notes',
        sortable: false,
        width:'60px',
      },
      {
        label: 'Position Preference',
        field: 'position',
        sortable: false,
        width:'200px',
      },
      {
        label: 'Shift Preference',
        field: 'shift',
        sortable: false,
        width:'300px',
      },
      {
        label: 'Est. Start Date',
        field: 'estimatedStartDate',
        sortable: false,
        width:'160px',
      },
      {
        label: 'Background Check',
        field: 'check',
        sortable: false,
      },
      {
        label: 'Drug Screening',
        field: 'drug',
        sortable: false,
      },
      {
        label: 'Accurate Check',
        field: 'accurate',
        sortable: false,
        
      },
      {
        label: 'Reason for Cancelling',
        field: 'action',
        sortable: false,
        
      },
    ]
    }
  },
  created() {
    this.$store.dispatch('getPipeline', this.$route.params.id)
    // this.$store.dispatch('getEventFromIdAss', this.$route.params.id)
    this.$store.dispatch('getPipelineCancelled', this.$route.params.id)
    this.$store.dispatch('getPipelineHired', this.$route.params.id)
  },
  components: {
    Loader,
    PipelineNote,
    TrackerTable
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'pipeline', 'eventInfo', 'pipelineCancelled', 'pipelineHired']),
    showAccurate() {
      if (this.eventInfo && this.eventInfo.amazon || (this.eventInfo.subClients && this.eventInfo.subClients.some(e => e.title === 'Amazon'))) {
        return true
      } else {
        return false
      }
    },
    showDrug() {
      if (this.eventInfo && this.eventInfo.drugScreening) {
        return true
      } else {
        return false
      }
    },
    showBack() {
      if (this.eventInfo && this.eventInfo.backgroundCheck) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    formatAMPM(date) {

      if (typeof date === "string") {
        // console.log(date)
        let [hours, minutes] = date.split(":");
        let ampm = "AM";

        if (Number(hours) >= 12) {
          hours = Number(hours) - 12;
          ampm = "PM";
        }

        if (date === "00:00") {
          return `12:00 AM`;
        } else {
          return `${hours}:${minutes} ${ampm}`;
        }

      } else if (date instanceof Date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();

        let ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;

        let strTime = hours + ":" + minutes + " " + ampm;
        return strTime;

      }
      return date;
      
    },
    showNote(r) {
      this.activeItem = r
    },
    closeNote(r) {
      this.activeItem = null
    },
    updatePreference(row) {
      fb.usersCollection.doc(row.id).update({
        shiftPreference: row.shiftPreference
      })
    },
    formatStamp(stamp) {
      if(stamp) {
        const postedDate = new Date(stamp.seconds) * 1000;
        return moment(postedDate).format('MM/DD/YYYY')
      }
    },
    formatDate(q) {
      console.log(q)
      return moment(q).format('MM/DD/YYYY')
    },
   },
  beforeDestroy () {
    this.$store.dispatch('clearPipeline')
    this.$store.dispatch('clearPipelineCancelled')
    this.performingRequest = null
    delete this.performingRequest
  }
}
</script>