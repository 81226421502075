<template>
  <div style="overflow:auto; width:100%;" class="pt-1">
    <vue-good-table

          :columns="columns"
          :rows="pipeline"
           styleClass="vgt-table striped"
          :pagination-options="{
            enabled: true,
            mode: 'records',
            perPage: 50,
          }"
          :class="classification"
          
        >
          <template slot="table-column" slot-scope="props">
            <span v-if="(props.column.label =='Accurate Check')">
              <div v-if="showAccurate">
                Accurate Check
              </div>
            </span>

            <span v-else-if="(props.column.label =='Background Check')">
              <div v-if="showBack">
                Background Check
              </div>
            </span>
            <span v-else-if="(props.column.label =='Drug Screening')">
              <div v-if="showDrug">
                Drug Screening
              </div>
            </span>
            <span v-else-if="(props.column.label =='Reason for Cancelling')">
              <div v-if="classification && classification == 'cancelled'">
                Reason for Cancelling
              </div>
              <div v-if="classification && classification != 'cancelled'">
                Next Steps
              </div>
            </span>


            
            <span v-else>
              {{props.column.label}}
           </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'photoUrl'">

                <img :src="(props.row.photoUrl || `https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/avatar%20copy.png?alt=media&token=966c07c4-125a-490f-81be-4e2d26bf33fa`)" alt="" style="width: 4rem; height:4rem; border-radius: 50%; padding: 0.25rem; filter: brightness(0.94);">
     
            </span>
            <span v-else-if="props.column.field == 'fullName'">
              <span class="flex">
                <h5>
                  {{props.row.firstName}} {{props.row.lastName}}
                </h5>
                <span>
                  <span v-if="props.row.beeline"><i class="fa-duotone fa-bee ml-3" style="--fa-primary-color: #6b6b6b; --fa-secondary-color: #fff700; --fa-secondary-opacity: 0.8;"></i></span>
                  <!-- <span v-if="props.row.id_card_path">
                    <v-popover>
                      <i class="fa-solid fa-address-card ml-3" style="color:#333333;"></i>
                      <template slot="popover" style="display: inline;">
                        <img :src="props.row.id_card_path" alt="" style="width:100%;">
                      </template>
                    </v-popover>
                  </span> -->
                </span>
              </span>
              <!-- <div>
                {{props.row.email}}
              </div>
              <div>
                 DOB: {{props.row.dob | moment("MM/DD/YYYY") }}
              </div> -->
              <!-- <span class="mt-0">
                <star-rating :read-only="true" :star-size="12" v-if="props.row && props.row.rating" v-model="props.row.rating"></star-rating>
              </span>
              <div class="flex justify-flex-start mt-0">
                <span v-if="props.row && props.row.points" class=" flex align-center">
                  {{props.row.points}} Points
                </span>
              </div> -->
      
              <!-- <div class="mt-0 " v-if="props.row.hireDate" style="color: #1497c6;">HD: {{props.row.hireDate | moment("MM/DD/YYYY")}}</div>
              <div class="mt-0 " v-if="props.row.terminationDate" style="color:#ef4b46;">TD: {{props.row.terminationDate | moment("MM/DD/YYYY")}}</div> -->
              <!-- <div class="flex">
                <span v-if="props.row.id_card_path">
                  <v-popover>
                    <i class="fa-solid fa-address-card mr-3" style="color:#333333;"></i>
                    <template slot="popover" style="display: inline;">
                      <img :src="props.row.id_card_path" alt="" style="width:100%;">
                    </template>
                  </v-popover>
                </span>
                <span v-if="props.row.picture">
                  <a :href="props.row.picture" target="_blank">
                    <i class="fa-regular fa-file mr-3" style="color:#333333;"></i>
                  </a>
                </span>
              </div> -->
              
              
            </span>
            <!-- <span v-else-if="props.column.field == 'created'">
              {{formatDate(props.row.created)}}
            </span> -->

            <span v-else-if="props.column.field == 'estimatedStartDate'">
              <div v-if="props.row.estimatedStartDate">
                {{props.row.estimatedStartDate | moment("MM/DD/YYYY")}}
              </div>

                    
            </span>

            <span v-else-if="props.column.field == 'notes'">

                <button v-show="!props.row.pipelineNote" class="btn btn__flat btn__icon pa-0" @click="showNote(props.row)" v-tooltip="'Leave a note'"><i class="far fa-sticky-note fa-2x ml-3 mr-3" style="opacity:0.5;"></i></button>
                <button v-show="props.row.pipelineNote" class="btn btn__flat btn__icon pa-0" @click="showNote(props.row)" v-tooltip="'Leave a note'"><i class="fa-solid fa-sticky-note fa-2x ml-3 mr-3" style="color:##f0ad4e;"></i></button>
                <transition name="modal">
                  <div v-if="activeItem == props.row">
                    <PipelineNote :item="props.row" @close="closeNote(props.row)" />
                  </div>
                </transition>
           
            </span>
            <span v-else-if="props.column.field == 'position'">

              <div style="width: 160px;" v-if="props.row.positionPreference && props.row.positionPreference[0]">
                {{props.row.positionPreference[0].title}}
              </div>

              <div style="width: 160px;" v-else-if="props.row.positionPreference && !props.row.positionPreference[0]">
                {{props.row.positionPreference.title}}
              </div>

              
            </span>
            <span v-else-if="props.column.field == 'shift'">
              <div style="width: 240px;" v-if="props.row && props.row.shiftPreference && props.row.shiftPreference[0] && props.row.shiftPreference[0].title">
                {{props.row.shiftPreference[0].title}} - 
                <v-popover style="display:inline;">
                  <button class="btn btn__xsmall btn__outlined" >See Hours</button>
                  <template slot="popover">
                   <div class="strong mb-1">Shift Schedule</div>
                    <div class="caption mb-1" v-if="props.row.shiftPreference[0] && props.row.shiftPreference[0].sundayStart && props.row.shiftPreference[0].sundayEnd">
                      Sunday: {{formatAMPM(props.row.shiftPreference[0].sundayStart)}} - {{formatAMPM(props.row.shiftPreference[0].sundayEnd) | moment("h:mm:ss a") }}
                    </div>
                    <div class="caption mb-1" v-if="props.row.shiftPreference[0] && props.row.shiftPreference[0].mondayStart && props.row.shiftPreference[0].mondayEnd">
                      Monday: {{formatAMPM(props.row.shiftPreference[0].mondayStart)}} - {{formatAMPM(props.row.shiftPreference[0].mondayEnd) | moment("h:mm:ss a") }}
                    </div>
                    <div class="caption mb-1" v-if="props.row.shiftPreference[0] && props.row.shiftPreference[0].tuesdayStart && props.row.shiftPreference[0].tuesdayEnd">
                      Tuesday: {{formatAMPM(props.row.shiftPreference[0].tuesdayStart)}} - {{formatAMPM(props.row.shiftPreference[0].tuesdayEnd) | moment("h:mm:ss a") }}
                    </div>
                    <div class="caption mb-1" v-if="props.row.shiftPreference[0] && props.row.shiftPreference[0].wednesdayStart && props.row.shiftPreference[0].wednesdayEnd">
                      Wednesday: {{formatAMPM(props.row.shiftPreference[0].wednesdayStart)}} - {{formatAMPM(props.row.shiftPreference[0].wednesdayEnd) | moment("h:mm:ss a") }}
                    </div>
                    <div class="caption mb-1" v-if="props.row.shiftPreference[0] && props.row.shiftPreference[0].thursdayStart && props.row.shiftPreference[0].thursdayEnd">
                      Thursday: {{formatAMPM(props.row.shiftPreference[0].thursdayStart)}} - {{formatAMPM(props.row.shiftPreference[0].thursdayEnd) | moment("h:mm:ss a") }}
                    </div>
                    <div class="caption mb-1" v-if="props.row.shiftPreference[0] && props.row.shiftPreference[0].fridayStart && props.row.shiftPreference[0].fridayEnd">
                      Friday: {{formatAMPM(props.row.shiftPreference[0].fridayStart)}} - {{formatAMPM(props.row.shiftPreference[0].fridayEnd) | moment("h:mm:ss a") }}
                    </div>
                    <div class="caption mb-1" v-if="props.row.shiftPreference[0] && props.row.shiftPreference[0].saturdayStart && props.row.shiftPreference[0].saturdayEnd">
                      Saturday: {{formatAMPM(props.row.shiftPreference[0].saturdayStart)}} - {{formatAMPM(props.row.shiftPreference[0].saturdayEnd) | moment("h:mm:ss a") }}
                    </div>

                  </template>
                </v-popover>
                
              </div>
            </span>

            <span v-else-if="props.column.field == 'check'">
              <div v-if="showBack" style="width:180px;">
              <!-- {{props.row.checkInvitations[0].payload}} -->

              <div v-if="props.row.checkOrderResults && (props.row.checkOrderResults[0]  && props.row.checkOrderResults[0].payload) && !props.row.backgroundCheckResponse">
                <div class=" strong">Results Pending</div>
              </div>
              <div v-else>

              <div v-if="(props.row.checkInvitations && props.row.checkInvitations[0] && props.row.checkInvitations[0].payload && props.row.checkInvitations[0].payload.applicantinfo) && !props.row.backgroundCheckResponse">
                <span v-if="props.row.checkInvitations && props.row.checkInvitations[0] && props.row.checkInvitations[0].payload && props.row.checkInvitations[0].payload.result">
                  <div class=" strong">Order Placed</div>
                  <!-- <div>{{props.row.checkInvitations[0].payload.applicantinfo.reference}}</div> -->
                  <!-- {{props.row.checkInvitations[0].payload.result.status}} -->

                  <span v-if="props.row.checkInvitations && props.row.checkInvitations[0] && props.row.checkInvitations[0].payload && props.row.checkInvitations[0].payload.result">
                    <span v-if="props.row.checkInvitationResponses && props.row.checkInvitationResponses[0] && props.row.checkInvitationResponses[0].payload">
                       <span v-if="props.row.checkInvitationResponses[0].payload[0] && props.row.checkInvitationResponses[0].payload[0].DateCreated">{{props.row.checkInvitationResponses[0].payload[0].DateCreated | moment("MM/DD/YYYY")}}</span>
                    </span>
                  </span>
         <!--          
                  <span v-if="props.row.checkInvitationResponses && props.row.checkInvitationResponses[0] && props.row.checkInvitationResponses[0].payload">
                     Order Placed<div>{{props.row.checkInvitationResponses[0].payload[0].DateCreated | moment("MM/DD/YYYY")}}</div>
                  </span> -->
                </span>
                

              </div>
              </div>

              


              <div v-if="props.row.backgroundCheckResponse">
                <div class=" strong">{{props.row.backgroundCheckResponse}}</div>
              </div>
            </div>

            </span>
            <span v-else-if="props.column.field == 'drug'">
              <div v-if="showDrug" style="width:180px;">

                <div v-if="props.row.drugOrderResults && (props.row.drugOrderResults[1] && props.row.drugOrderResults[1].payload) && !props.row.drugCheckResponse"><div class=" strong">Results Pending</div>
                </div>
                <div v-else>
              

                <div v-if="(props.row.drugInvitations && props.row.drugInvitations[0] && props.row.drugInvitations[0].payload && props.row.drugInvitations[0].payload.applicantinfo) && !props.row.drugCheckResponse">
                  <span v-if="props.row.drugInvitations && props.row.drugInvitations[0] && props.row.drugInvitations[0].payload && props.row.drugInvitations[0].payload.result">
                    <span v-if="props.row.drugInvitationResponses && props.row.drugInvitationResponses[0] && props.row.drugInvitationResponses[0].payload">
                      <div class=" strong">Order Placed</div>
                       <div>{{props.row.drugInvitationResponses[0].payload[0].DateCreated | moment("MM/DD/YYYY")}}</div>
                    </span>
                  </span>

                </div>
                </div>

                


                <div v-if="props.row.drugCheckResponse">
                  <div class=" strong">{{props.row.drugCheckResponse}}</div>
                </div>
              </div>

            </span>
            <span v-else-if="props.column.field == 'accurate'">
              <div v-if="showAccurate" style="width:180px;">
                <div class=" mb-1" v-if="props.row && props.row.accurateCheckCreated && props.row.accurateCheckCreated != null">

                  <!-- Check Ordered: {{props.row.accurateCheckCreated.toDate() | moment("MM/DD/YYYY")}} -->

                
                </div>
                <div v-if="props.row.accurateCheckResponse && props.row.accurateCheckResponse != 'Passed'">{{props.row.accurateCheckResponse}}<div>{{formatStamp(props.row.accurateCheckCreated)}}</div></div>
                <div v-else-if="props.row.accurateCheckResponse && props.row.accurateCheckResponse == 'Passed'" class="strong">{{props.row.accurateCheckResponse}}</div>
                <div class="mb-1" v-if="(props.row && props.row.accurateCheckRef)">
                  #{{props.row.accurateCheckRef}}
                </div>
              </div>
            </span>

            <span v-else-if="props.column.field == 'action' && !props.row.cancellationReason && classification == 'pipeline'">

              <span v-if="((!showAccurate || (showAccurate && props.row.accurateCheckResponse && props.row.accurateCheckResponse == 'Passed')) && 
              (!showBack || (showBack && props.row.backgroundCheckResponse && props.row.backgroundCheckResponse == 'Passed')) && 
              (!showDrug || (showDrug && props.row.drugCheckResponse && props.row.drugCheckResponse == 'Passed')))">
                <span class="strong" style="color:green;">Ready to Start</span>
              </span>
              <span v-if="props.row.cancellationReason">{{props.row.cancellationReason}}</span>
            </span>

            <span v-else-if="props.column.field == 'action' && classification == 'hired'">
              <span class="strong" style="color:green;">Hired Worker</span>
            </span>

            <span v-else-if="props.column.field == 'action' && props.row.cancellationReason && classification == 'cancelled'">
              <span v-if="props.row.cancellationReason">{{props.row.cancellationReason}}</span>
            </span>



            
            

             <span v-else>
              <span>
                {{props.formattedRow[props.column.field]}}
              </span>
            </span>
          </template>
        </vue-good-table>
  </div>
</template>

<style scoped>
.dashboard {
  background: rgb(38 47 61 / 93%) !important;
  padding-top: 2rem;
  padding-bottom: 4rem;
  min-height: calc(100vh - 6rem);
}
.fa-2x {
  font-size: 2rem;
}
h5 {
  font-size: 1.4rem;
  opacity: 1;
}
input {
  font-size: 1.4rem;
  background: #eee;
  border: none;
}
/* .btn__flat {
  color: white;
} */
.dashboard__container--header {
  align-items: flex-start;
}

.cancelled h5 {
    color: red !important;
  }
  .hired h5 {
    color: green !important;
  }
  .dashboard__container {
    max-width: 90%;
  }
  
</style>

<script>
import { mapState } from 'vuex'
import PipelineNote from '@/components/PipelineNote.vue'
import * as moment from 'moment'

export default {
  props: ['classification', 'showAccurate', 'showDrug', 'showBack', 'pipeline'],
  data() {
    return {
      activeItem: '',
      showShiftPreference: false,
      columns: [
      {
        field: 'photoUrl',
        sortable: false,
        width:'60px',
        tdClass: 'text-center',
      },
      {
        label: 'Candidates',
        field: 'fullName',
        sortable: false,
        width:'200px',
      },
      {
        label: 'Notes',
        field: 'notes',
        sortable: false,
        width:'60px',
      },
      {
        label: 'Position Preference',
        field: 'position',
        sortable: false,
        width:'200px',
      },
      {
        label: 'Shift Preference',
        field: 'shift',
        sortable: false,
        width:'200px',
      },
      {
        label: 'Est. Start Date',
        field: 'estimatedStartDate',
        sortable: false,
        width:'200px',
      },
      {
        label: 'Background Check',
        field: 'check',
        sortable: false,
      },
      {
        label: 'Drug Screening',
        field: 'drug',
        sortable: false,
      },
      {
        label: 'Accurate Check',
        field: 'accurate',
        sortable: false,
        
      },
      {
        label: 'Reason for Cancelling',
        field: 'action',
        sortable: false,
        width:'200px',
        
      },
    ]
    }
  },
  components: {
    PipelineNote
  },
  // computed: {
  //   classObject() {
  //     cancelled: this.type && this.type == 'cancelled'
  //   }
  // },
  methods: {
    formatAMPM(date) {

      if (typeof date === "string") {
        // console.log(date)
        let [hours, minutes] = date.split(":");
        let ampm = "AM";

        if (Number(hours) >= 12) {
          hours = Number(hours) - 12;
          ampm = "PM";
        }

        if (date === "00:00") {
          return `12:00 AM`;
        } else {
          return `${hours}:${minutes} ${ampm}`;
        }

      } else if (date instanceof Date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();

        let ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;

        let strTime = hours + ":" + minutes + " " + ampm;
        return strTime;

      }
      return date;
      
    },
    showNote(r) {
      this.activeItem = r
    },
    closeNote(r) {
      this.activeItem = null
    },
    updatePreference(row) {
      fb.usersCollection.doc(row.id).update({
        shiftPreference: row.shiftPreference
      })
    },
    formatStamp(stamp) {
      if(stamp) {
        const postedDate = new Date(stamp.seconds) * 1000;
        return moment(postedDate).format('MM/DD/YYYY')
      }
    },
    formatDate(q) {
      console.log(q)
      return moment(q).format('MM/DD/YYYY')
    },
   },
}
</script>